<template>
  <v-container fluid>
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t('system.env')"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="footerProps"
        multi-sort
      >
        <template v-slot:item.value="{ item }">

          <v-edit-dialog
            @save="updateField(item.id,'value',item.value)"
            :save-text="$t('save')"
            :cancel-text="$t('cancel')"
            large
          >
            {{ item.value }} <v-icon>mdi-pencil</v-icon>
            <template v-slot:input>
              <v-text-field v-model="item.value"></v-text-field>
            </template>
          </v-edit-dialog>

        </template>
        <template v-slot:item.description="{ item }">
          <v-edit-dialog
            @save="updateField(item.id,'description',item.description)"
            :save-text="$t('save')"
            :cancel-text="$t('cancel')"
            large
          >
            {{ item.description }} <v-icon>mdi-pencil</v-icon>
            <template v-slot:input>
              <v-text-field v-model="item.description"></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>
<script>
import BaseTable from "./BaseTable.vue";
export default {
  data: (vm) => ({
    url: "/system/system_variables/",
    headers: [
      {
        sortable: true,
        text: vm.$i18n.t("system.key"),
        value: "key",
        width: "5%",
      },
      {
        sortable: true,
        text: vm.$i18n.t("system.value"),
        value: "value",
        width: "5%",
      },
      {
        sortable: true,
        text: vm.$i18n.t("description"),
        value: "description",
        width: "5%",
      },
    ],
  }),
  mixins: [BaseTable],
};
</script>